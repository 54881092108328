define("discourse/plugins/stemaway-custom-pages/discourse/controllers/home", ["exports", "@ember/controller", "discourse-common/utils/decorators"], function (_exports, _controller, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _controller.default.extend(dt7948.p({
    studentCollage() {
      const prefix = "student-image-collage/collage";
      const numImages = 17;
      const collage = [];
      let i = 1;
      while (i <= numImages) {
        collage.push({
          imgPath: `${prefix}-${i}.jpg`
        });
        i++;
      }
      return collage;
    }
  }, [["method", "studentCollage", [(0, _decorators.default)()]]]));
});