define("discourse/plugins/stemaway-custom-pages/discourse/templates/components/route-control", ["exports", "ember-this-fallback/deprecations-helper", "@ember/template-factory"], function (_exports, _deprecationsHelper, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if canDisplay}}
    {{yield}}
  {{/if}}
  */
  {
    "id": "Rc3Y6KC7",
    "block": "[[[41,[30,0,[\"canDisplay\"]],[[[1,\"  \"],[18,1,null],[1,\"\\n\"]],[]],null],[1,[28,[32,0],[\"[[\\\"The `canDisplay` property path was used in the `discourse/plugins/stemaway-custom-pages/discourse/templates/components/route-control.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.canDisplay}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}]]\"],null]]],[\"&default\"],false,[\"if\",\"yield\"]]",
    "moduleName": "discourse/plugins/stemaway-custom-pages/discourse/templates/components/route-control.hbs",
    "scope": () => [_deprecationsHelper.default],
    "isStrictMode": false
  });
});