define("discourse/plugins/stemaway-custom-pages/discourse/components/stem-card", ["exports", "@ember/component", "@ember/object", "discourse/lib/url", "I18n"], function (_exports, _component, _object, _url, _I18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend(dt7948.p({
    tagName: "div",
    classNames: ["stem-card"],
    classNameBindings: ["url:stem-card-clickable"],
    click() {
      // don't create a link if url is empty
      if (this.url === "") {
        return;
      }
      return _url.default.routeTo(_I18n.default.t(this.url));
    }
  }, [["method", "click", [_object.action]]]));
});