define("discourse/plugins/stemaway-custom-pages/discourse/components/stem-tab", ["exports", "@ember/component", "@ember/object", "discourse-common/utils/decorators"], function (_exports, _component, _object, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend(dt7948.p({
    tagName: "div",
    classNames: ["stem-tab-wrapper"],
    handleIt(attrs) {
      this.set("activeTab", attrs.id);
      this.set("currentContent", attrs.content);
      return;
    },
    isActive(id, activeTab) {
      if (id === activeTab) {
        return "active";
      }
    }
  }, [["method", "handleIt", [_object.action]], ["method", "isActive", [(0, _decorators.default)("t.id", "activeTab")]]]));
});