define("discourse/plugins/stemaway-custom-pages/discourse/templates/components/only-guest", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.canDisplay}}
    {{yield}}
  {{/if}}
  */
  {
    "id": "6uoyo1OF",
    "block": "[[[41,[30,0,[\"canDisplay\"]],[[[1,\"  \"],[18,1,null],[1,\"\\n\"]],[]],null]],[\"&default\"],false,[\"if\",\"yield\"]]",
    "moduleName": "discourse/plugins/stemaway-custom-pages/discourse/templates/components/only-guest.hbs",
    "isStrictMode": false
  });
});