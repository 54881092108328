define("discourse/plugins/stemaway-custom-pages/discourse/controllers/testimonials", ["exports", "@ember/controller", "discourse-common/utils/decorators", "I18n"], function (_exports, _controller, _decorators, _I18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _controller.default.extend(dt7948.p({
    pathwayTestimonials() {
      const prefix = "pages.testimonials.pathway_testimonials";
      const info = [];
      let i = 1;
      while (_I18n.default.t(`${prefix}.testimonial_${i}.mentor`)[0] !== "[" && _I18n.default.t(`${prefix}.testimonial_${i}.mentor`).substr(-1) !== "]") {
        info.push({
          mentor: _I18n.default.t(`${prefix}.testimonial_${i}.mentor`),
          pathway: _I18n.default.t(`${prefix}.testimonial_${i}.pathway`),
          icon: _I18n.default.t(`${prefix}.testimonial_${i}.icon`),
          image: _I18n.default.t(`${prefix}.testimonial_${i}.image`),
          testimonial: _I18n.default.t(`${prefix}.testimonial_${i}.testimonial`),
          author: _I18n.default.t(`${prefix}.testimonial_${i}.author`),
          authorTitle: _I18n.default.t(`${prefix}.testimonial_${i}.author_title`)
        });
        i++;
      }
      return info;
    }
  }, [["method", "pathwayTestimonials", [(0, _decorators.default)()]]]));
});