define("discourse/plugins/stemaway-custom-pages/discourse/templates/components/stem-team-card-set", ["exports", "ember-this-fallback/deprecations-helper", "@ember/template-factory"], function (_exports, _deprecationsHelper, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#each teamSet as |participant|}}
    {{stem-team-card
      src=participant.src
      altText=participant.altText
      name=participant.name
      url=participant.url
      category=participant.position
      description=participant.description
    }}
  {{/each}}
  */
  {
    "id": "BiuJnRqZ",
    "block": "[[[42,[28,[37,1],[[28,[37,1],[[30,0,[\"teamSet\"]]],null]],null],null,[[[1,\"  \"],[1,[28,[35,2],null,[[\"src\",\"altText\",\"name\",\"url\",\"category\",\"description\"],[[30,1,[\"src\"]],[30,1,[\"altText\"]],[30,1,[\"name\"]],[30,1,[\"url\"]],[30,1,[\"position\"]],[30,1,[\"description\"]]]]]],[1,\"\\n\"]],[1]],null],[1,[28,[32,0],[\"[[\\\"The `teamSet` property path was used in the `discourse/plugins/stemaway-custom-pages/discourse/templates/components/stem-team-card-set.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.teamSet}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}]]\"],null]]],[\"participant\"],false,[\"each\",\"-track-array\",\"stem-team-card\"]]",
    "moduleName": "discourse/plugins/stemaway-custom-pages/discourse/templates/components/stem-team-card-set.hbs",
    "scope": () => [_deprecationsHelper.default],
    "isStrictMode": false
  });
});