define("discourse/plugins/stemaway-custom-pages/discourse/templates/connectors/discovery-above/stem-connections", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    
  */
  {
    "id": "Tm2fZnJk",
    "block": "[[],[],false,[]]",
    "moduleName": "discourse/plugins/stemaway-custom-pages/discourse/templates/connectors/discovery-above/stem-connections.hbs",
    "isStrictMode": false
  });
});