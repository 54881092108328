define("discourse/plugins/stemaway-custom-pages/discourse/components/stem-notification", ["exports", "@ember/component", "discourse-common/utils/decorators"], function (_exports, _component, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend(dt7948.p({
    tagName: "div",
    classNames: ["stem-banner-notification"],
    classNameBindings: ["type"],
    icon(type) {
      switch (type) {
        case "announcement":
          return "bullhorn";
          break;
        case "notification":
          return "bell";
          break;
        case "warning":
          return "exclamation-triangle";
          break;
        case "alert":
          return "exclamation";
          break;
        default:
          return "bell";
      }
    },
    didInsertElement() {
      this._super(...arguments);
      document.body.classList.add("stem-notification-active");
    },
    willDestroyElement() {
      this._super(...arguments);
      document.body.classList.remove("stem-notification-active");
    }
  }, [["method", "icon", [(0, _decorators.default)("type")]]]));
});