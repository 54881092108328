define("discourse/plugins/stemaway-custom-pages/discourse/templates/projects/career-explorations/hardware-engineering", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="guide-page unfinished-guide">
    <h1>
      Hardware Engineering Pathway Guide
    </h1>
    <h2>
      This guide is coming soon. Check back later.
    </h2>
    <h3>
      In the meantime:
    </h3>
    <ul>
      <li>
        <a href="https://stemaway.com/c/pathway-hubs/hardware-engineering/167">
          Visit the Forum
        </a>
      </li>
      <li>
        <a href="../../projects">
          View Another Guide
        </a>
      </li>
    </ul>
    <img
      src="/plugins/stemaway-custom-pages/images/illustrations/under-construction.svg"
      alt=""
    >
  </div>
  */
  {
    "id": "bGlGiQg2",
    "block": "[[[10,0],[14,0,\"guide-page unfinished-guide\"],[12],[1,\"\\n  \"],[10,\"h1\"],[12],[1,\"\\n    Hardware Engineering Pathway Guide\\n  \"],[13],[1,\"\\n  \"],[10,\"h2\"],[12],[1,\"\\n    This guide is coming soon. Check back later.\\n  \"],[13],[1,\"\\n  \"],[10,\"h3\"],[12],[1,\"\\n    In the meantime:\\n  \"],[13],[1,\"\\n  \"],[10,\"ul\"],[12],[1,\"\\n    \"],[10,\"li\"],[12],[1,\"\\n      \"],[10,3],[14,6,\"https://stemaway.com/c/pathway-hubs/hardware-engineering/167\"],[12],[1,\"\\n        Visit the Forum\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,\"li\"],[12],[1,\"\\n      \"],[10,3],[14,6,\"../../projects\"],[12],[1,\"\\n        View Another Guide\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,\"img\"],[14,\"src\",\"/plugins/stemaway-custom-pages/images/illustrations/under-construction.svg\"],[14,\"alt\",\"\"],[12],[13],[1,\"\\n\"],[13]],[],false,[]]",
    "moduleName": "discourse/plugins/stemaway-custom-pages/discourse/templates/projects/career-explorations/hardware-engineering.hbs",
    "isStrictMode": false
  });
});