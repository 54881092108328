define("discourse/plugins/stemaway-custom-pages/discourse/components/guide-objectives", ["exports", "@ember/component", "discourse-common/utils/decorators", "I18n", "discourse/plugins/stemaway-custom-pages/discourse/lib/filterBlanks"], function (_exports, _component, _decorators, _I18n, _filterBlanks) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend(dt7948.p({
    tagName: "ul",
    objectiveSet() {
      const prefix = this.get("prefix");
      const objectiveSet = [];
      let i = 1;
      while (_I18n.default.t(`${prefix}.objective_${i}`)[0] !== "[" && _I18n.default.t(`${prefix}.objective_${i}`).substr(-1) !== "]") {
        objectiveSet.push({
          content: _I18n.default.t(`${prefix}.objective_${i}`)
        });
        i++;
      }
      return (0, _filterBlanks.default)(objectiveSet, "content");
    }
  }, [["method", "objectiveSet", [(0, _decorators.default)("prefix")]]]));
});