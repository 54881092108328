define("discourse/plugins/stemaway-custom-pages/discourse/components/guide-resources", ["exports", "@ember/component", "discourse-common/utils/decorators", "I18n"], function (_exports, _component, _decorators, _I18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend(dt7948.p({
    tagName: "div",
    classNames: ["guide-resources-cards"],
    resourceSet() {
      const prefix = this.get("prefix");
      const resourceSet = [];
      let i = 1;
      while (_I18n.default.t(`${prefix}.resource_${i}.title`)[0] !== "[" && _I18n.default.t(`${prefix}.resource_${i}.title`).substr(-1) !== "]") {
        let special = _I18n.default.t(`${prefix}.resource_${i}.logo`)[0] === "#" ? true : false;
        let path = _I18n.default.t(`${prefix}.resource_${i}.logo`);
        let logo = special ? `<use xlink:href=${path}></use>` : _I18n.default.t(`${prefix}.resource_${i}.logo`);
        resourceSet.push({
          title: `${prefix}.resource_${i}.title`,
          logo,
          list: `${prefix}.resource_${i}.list`,
          special
        });
        i++;
      }
      return resourceSet;
    }
  }, [["method", "resourceSet", [(0, _decorators.default)("prefix")]]]));
});