define("discourse/plugins/stemaway-custom-pages/discourse/components/pathway-skills", ["exports", "@ember/component", "discourse/lib/ajax", "I18n"], function (_exports, _component, _ajax, _I18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend({
    tagName: "div",
    classNames: ["pathway-skills-container"],
    didInsertElement() {
      this._super(...arguments);
      const searchTagGroups = _I18n.default.t(this.get("searchTagGroups")).split("|");
      (0, _ajax.ajax)(`/tags.json`).then(_ref => {
        let {
          extras
        } = _ref;
        const tagGroups = extras.tag_groups;
        const filteredTagGroups = tagGroups.filter(tagGroup => {
          return searchTagGroups.includes(tagGroup.name);
        });
        this.set("skillTagGroups", filteredTagGroups);
      });
    }
  });
});