define("discourse/plugins/stemaway-custom-pages/discourse/components/work-ready-synonyms", ["exports", "@ember/component", "discourse-common/utils/decorators", "I18n"], function (_exports, _component, _decorators, _I18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend(dt7948.p({
    tagName: "div",
    classNames: ["work-ready-synonyms-container"],
    searchResults(prefix) {
      const results = [];
      let i = 1;
      while (_I18n.default.t(`${prefix}_${i}`)[0] !== "[" && _I18n.default.t(`${prefix}_${i}`).substr(-1) !== "]") {
        results.push({
          title: _I18n.default.t(`${prefix}_${i}`)
        });
        i++;
      }
      return results;
    }
  }, [["method", "searchResults", [(0, _decorators.default)("prefix")]]]));
});