define("discourse/plugins/stemaway-custom-pages/discourse/components/universities-ticker", ["exports", "@ember/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend({
    universities: ["virginia", "texas", "berkely", "carnegie", "cornell", "sanjose"],
    didInsertElement() {
      this._super(...arguments);
    }
  });
});