define("discourse/plugins/stemaway-custom-pages/discourse/components/stem-team-card-set", ["exports", "@ember/component", "discourse-common/utils/decorators", "I18n"], function (_exports, _component, _decorators, _I18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend(dt7948.p({
    tagName: "div",
    classNames: ["spages-team-gallery"],
    teamSet(prefix) {
      const teamSet = [];
      let i = 1;
      while (_I18n.default.t(`${prefix}_${i}.name`)[0] !== "[" && _I18n.default.t(`${prefix}_${i}.name`).substr(-1) !== "]") {
        teamSet.push({
          name: _I18n.default.t(`${prefix}_${i}.name`),
          position: _I18n.default.t(`${prefix}_${i}.position`),
          src: _I18n.default.t(`${prefix}_${i}.src`),
          altText: _I18n.default.t(`${prefix}_${i}.alt_text`),
          description: _I18n.default.t(`${prefix}_${i}.description`),
          url: _I18n.default.t(`${prefix}_${i}.link`)
        });
        i++;
      }
      return teamSet;
    }
  }, [["method", "teamSet", [(0, _decorators.default)("prefix")]]]));
});