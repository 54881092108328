define("discourse/plugins/stemaway-custom-pages/discourse/components/stem-pathway-card-set", ["exports", "@ember/component", "discourse-common/utils/decorators", "I18n"], function (_exports, _component, _decorators, _I18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend(dt7948.p({
    tagName: "div",
    classNames: ["stem-pathway-card-set"],
    pathwaySet(prefix) {
      const pathwaySet = [];
      let i = 1;
      while (_I18n.default.t(`${prefix}_${i}.title`)[0] !== "[" && _I18n.default.t(`${prefix}_${i}.title`).substr(-1) !== "]") {
        pathwaySet.push({
          title: `${prefix}_${i}.title`,
          url: _I18n.default.t(`${prefix}_${i}.link`),
          imgPath: _I18n.default.t(`${prefix}_${i}.img_path`)
        });
        i++;
      }
      return pathwaySet;
    }
  }, [["method", "pathwaySet", [(0, _decorators.default)("prefix")]]]));
});