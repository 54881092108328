define("discourse/plugins/stemaway-custom-pages/discourse/templates/components/stem-pathway-card-set", ["exports", "ember-this-fallback/deprecations-helper", "@ember/template-factory"], function (_exports, _deprecationsHelper, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#each pathwaySet as |pathway|}}
    {{stem-pathway-card
      title=pathway.title
      imgPath=pathway.imgPath
      loading=lazy
      url=pathway.url
    }}
  {{/each}}
  */
  {
    "id": "EPtEIIWi",
    "block": "[[[42,[28,[37,1],[[28,[37,1],[[30,0,[\"pathwaySet\"]]],null]],null],null,[[[1,\"  \"],[1,[28,[35,2],null,[[\"title\",\"imgPath\",\"loading\",\"url\"],[[30,1,[\"title\"]],[30,1,[\"imgPath\"]],[30,0,[\"lazy\"]],[30,1,[\"url\"]]]]]],[1,\"\\n\"]],[1]],null],[1,[28,[32,0],[\"[[\\\"The `pathwaySet` property path was used in the `discourse/plugins/stemaway-custom-pages/discourse/templates/components/stem-pathway-card-set.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.pathwaySet}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}],[\\\"The `lazy` property path was used in the `discourse/plugins/stemaway-custom-pages/discourse/templates/components/stem-pathway-card-set.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.lazy}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}]]\"],null]]],[\"pathway\"],false,[\"each\",\"-track-array\",\"stem-pathway-card\"]]",
    "moduleName": "discourse/plugins/stemaway-custom-pages/discourse/templates/components/stem-pathway-card-set.hbs",
    "scope": () => [_deprecationsHelper.default],
    "isStrictMode": false
  });
});