define("discourse/plugins/stemaway-custom-pages/discourse/components/testimonial", ["exports", "@ember/component", "discourse-common/utils/decorators", "I18n"], function (_exports, _component, _decorators, _I18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend(dt7948.p({
    tagName: "div",
    classNames: ["quote-card"],
    imgLocation(imgPath) {
      const imgUrl = _I18n.default.t(imgPath);
      return `/plugins/stemaway-custom-pages/images/${imgUrl}`;
    }
  }, [["method", "imgLocation", [(0, _decorators.default)("imgPath")]]]));
});