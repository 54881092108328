define("discourse/plugins/stemaway-custom-pages/discourse/lib/filterBlanks", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = filterBlanks;
  /**
   *
   * @param objectArray The array of objects to filter
   * @param objectKey The specific key to filter
   * @returns Filtered object array
   */
  function filterBlanks(objectArray, objectKey) {
    const filterKey = "%blank%";
    return objectArray.filter(o => o[objectKey] !== filterKey);
  }
});