define("discourse/plugins/stemaway-custom-pages/discourse/templates/components/guide-modules", ["exports", "ember-this-fallback/deprecations-helper", "@ember/template-factory"], function (_exports, _deprecationsHelper, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#each moduleSet as |module|}}
    <div class="guide-modules-cards-item">
      <a target="_blank" href={{module.url}} rel="noopener noreferrer">
        <h3>
          {{module.subtitle}}
          <strong>
            {{html-safe module.title}}
          </strong>
          {{d-icon "external-link-alt"}}
        </h3>
        {{guide-module-items prefix=module.list}}
      </a>
    </div>
  {{/each}}
  */
  {
    "id": "EtvOsnE1",
    "block": "[[[42,[28,[37,1],[[28,[37,1],[[30,0,[\"moduleSet\"]]],null]],null],null,[[[1,\"  \"],[10,0],[14,0,\"guide-modules-cards-item\"],[12],[1,\"\\n    \"],[10,3],[14,\"target\",\"_blank\"],[15,6,[30,1,[\"url\"]]],[14,\"rel\",\"noopener noreferrer\"],[12],[1,\"\\n      \"],[10,\"h3\"],[12],[1,\"\\n        \"],[1,[30,1,[\"subtitle\"]]],[1,\"\\n        \"],[10,\"strong\"],[12],[1,\"\\n          \"],[1,[28,[35,2],[[30,1,[\"title\"]]],null]],[1,\"\\n        \"],[13],[1,\"\\n        \"],[1,[28,[35,3],[\"external-link-alt\"],null]],[1,\"\\n      \"],[13],[1,\"\\n      \"],[1,[28,[35,4],null,[[\"prefix\"],[[30,1,[\"list\"]]]]]],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[1]],null],[1,[28,[32,0],[\"[[\\\"The `moduleSet` property path was used in the `discourse/plugins/stemaway-custom-pages/discourse/templates/components/guide-modules.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.moduleSet}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}]]\"],null]]],[\"module\"],false,[\"each\",\"-track-array\",\"html-safe\",\"d-icon\",\"guide-module-items\"]]",
    "moduleName": "discourse/plugins/stemaway-custom-pages/discourse/templates/components/guide-modules.hbs",
    "scope": () => [_deprecationsHelper.default],
    "isStrictMode": false
  });
});