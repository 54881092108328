define("discourse/plugins/stemaway-custom-pages/discourse/components/only-guest", ["exports", "@glimmer/component", "discourse/models/user"], function (_exports, _component, _user) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class OnlyGuest extends _component.default {
    get canDisplay() {
      return !_user.default.current();
    }
  }
  _exports.default = OnlyGuest;
});