define("discourse/plugins/stemaway-custom-pages/discourse/components/guide-resource-items", ["exports", "@ember/component", "discourse-common/utils/decorators", "I18n"], function (_exports, _component, _decorators, _I18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend(dt7948.p({
    listSet() {
      const prefix = this.get("prefix");
      const listSet = [];
      let i = 1;
      while (_I18n.default.t(`${prefix}.item_${i}.link`)[0] !== "[" && _I18n.default.t(`${prefix}.item_${i}.link`).substr(-1) !== "]") {
        listSet.push({
          content: `${prefix}.item_${i}.content`,
          url: `${prefix}.item_${i}.link`
        });
        i++;
      }
      return listSet;
    }
  }, [["method", "listSet", [(0, _decorators.default)("prefix")]]]));
});