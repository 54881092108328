define("discourse/plugins/stemaway-custom-pages/discourse/components/stem-category-insert", ["exports", "@ember/component", "@ember/service", "discourse-common/utils/decorators"], function (_exports, _component, _service, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend(dt7948.p({
    tagName: "div",
    router: (0, _service.inject)(),
    canDisplay(currentRoute, categories) {
      if (currentRoute === null) {
        return;
      }
      const validRoutes = ["discovery.categoryNone", "discovery.category", "discovery.categoryAll"];
      if (!validRoutes.includes(currentRoute.name)) {
        return;
      }
      const currentCategoryId = currentRoute.attributes.category.id;
      const categoryIds = categories.split("|");
      if (categoryIds.includes(currentCategoryId.toString())) {
        return true;
      } else {
        return false;
      }
    }
  }, [["method", "canDisplay", [(0, _decorators.default)("router.currentRoute", "categories")]]]));
});