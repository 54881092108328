define("discourse/plugins/stemaway-custom-pages/discourse/components/stem-image", ["exports", "@ember/component", "discourse-common/utils/decorators"], function (_exports, _component, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend(dt7948.p({
    imageBasePath: "/plugins/stemaway-custom-pages/images/",
    classNames: ["stem-image"],
    path(path) {
      return this.imageBasePath + path;
    }
  }, [["method", "path", [(0, _decorators.default)("imgPath")]]]));
});