define("discourse/plugins/stemaway-custom-pages/discourse/templates/connectors/before-create-topic-button/student-guide-button", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#stem-category-insert categories="15|72|257" class="pathway-playlist-button"}}
    <a data-fancybox href="https://youtu.be/fPx9C0f22HA" class="btn-default btn btn-icon-text">
      {{d-icon "info"}}
      {{i18n "components.student_guide.title"}}
    </a>
  {{/stem-category-insert}}
  */
  {
    "id": "c1BjE0+b",
    "block": "[[[6,[39,0],null,[[\"categories\",\"class\"],[\"15|72|257\",\"pathway-playlist-button\"]],[[\"default\"],[[[[1,\"  \"],[10,3],[14,\"data-fancybox\",\"\"],[14,6,\"https://youtu.be/fPx9C0f22HA\"],[14,0,\"btn-default btn btn-icon-text\"],[12],[1,\"\\n    \"],[1,[28,[35,1],[\"info\"],null]],[1,\"\\n    \"],[1,[28,[35,2],[\"components.student_guide.title\"],null]],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]]]]]],[],false,[\"stem-category-insert\",\"d-icon\",\"i18n\"]]",
    "moduleName": "discourse/plugins/stemaway-custom-pages/discourse/templates/connectors/before-create-topic-button/student-guide-button.hbs",
    "isStrictMode": false
  });
});