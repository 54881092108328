define("discourse/plugins/stemaway-custom-pages/discourse/components/stem-card-set", ["exports", "@ember/component", "discourse-common/utils/decorators", "I18n"], function (_exports, _component, _decorators, _I18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend(dt7948.p({
    tagName: "div",
    cardSet() {
      const prefix = this.get("prefix");
      const cardSet = [];
      let i = 1;
      while (_I18n.default.t(`${prefix}_${i}.title`)[0] !== "[" && _I18n.default.t(`${prefix}_${i}.title`).substr(-1) !== "]") {
        let url_path = "";
        if (_I18n.default.t(`${prefix}_${i}.link`)[0] !== "[" && _I18n.default.t(`${prefix}_${i}.link`).substr(-1) !== "]") {
          url_path = `${prefix}_${i}.link`;
        }
        let subtitle = "";
        if (_I18n.default.t(`${prefix}_${i}.subtitle`)[0] !== "[" && _I18n.default.t(`${prefix}_${i}.subtitle`).substr(-1) !== "]") {
          subtitle = `${prefix}_${i}.subtitle`;
        }
        cardSet.push({
          tagline: subtitle,
          title: `${prefix}_${i}.title`,
          description: `${prefix}_${i}.description`,
          imgPath: `${prefix}_${i}.img_path`,
          altText: `${prefix}_${i}.alt_text`,
          url: url_path
        });
        i++;
      }
      return cardSet;
    }
  }, [["method", "cardSet", [(0, _decorators.default)("prefix")]]]));
});