define("discourse/plugins/stemaway-custom-pages/discourse/stemaway-pages-route-map", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;
  function _default() {
    this.route("home", {
      path: "/home",
      resetNamespace: true
    });
    this.route("corporate-partners", {
      path: "/corporate-partners",
      resetNamespace: true
    });
    this.route("apply", {
      path: "/apply",
      resetNamespace: true
    });
    this.route("deals", {
      path: "/deals",
      resetNamespace: true
    });
    this.route("testimonials", {
      path: "/testimonials",
      resetNamespace: true
    });
    this.route("programs", {
      path: "/programs",
      resetNamespace: true
    });
    this.route("partners/index", {
      path: "/partners",
      resetNamespace: true
    });
    this.route("partners/examples", {
      path: "/partners/ad-examples",
      resetNamespace: true
    });

    // Projects
    this.route("projects/index", {
      path: "/projects",
      resetNamespace: true
    });

    // Career Exploration Projects
    this.route("projects/career-explorations/machine-learning", {
      path: "/projects/career-explorations/machine-learning",
      resetNamespace: true
    });
    this.route("projects/career-explorations/bioinformatics", {
      path: "/projects/career-explorations/bioinformatics",
      resetNamespace: true
    });
    this.route("projects/career-explorations/full-stack", {
      path: "/projects/career-explorations/full-stack",
      resetNamespace: true
    });
    this.route("projects/career-explorations/ux-ui", {
      path: "/projects/career-explorations/ux-ui",
      resetNamespace: true
    });
    this.route("projects/career-explorations/hardware-engineering", {
      path: "/projects/career-explorations/hardware-engineering",
      resetNamespace: true
    });

    // Career Advancement Projects
    this.route("projects/career-advancement/machine-learning", {
      path: "/projects/career-advancement/machine-learning",
      resetNamespace: true
    });
    this.route("projects/career-advancement/bioinformatics", {
      path: "/projects/career-advancement/bioinformatics",
      resetNamespace: true
    });
    this.route("projects/career-advancement/full-stack", {
      path: "/projects/career-advancement/full-stack",
      resetNamespace: true
    });
    this.route("projects/career-advancement/cloud-computing", {
      path: "/projects/career-advancement/cloud-computing",
      resetNamespace: true
    });
    this.route("hybrid-bootcamps-form", {
      path: "/hybrid-bootcamps/form",
      resetNamespace: true
    });
    this.route("virtual-internships-form", {
      path: "/virtual-internships/services",
      resetNamespace: true
    });
    this.route("mission-contribute", {
      path: "/mission/contribute",
      resetNamespace: true
    });
  }
});