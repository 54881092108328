define("discourse/plugins/stemaway-custom-pages/discourse/components/ad-example-carousel", ["exports", "@ember/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /* eslint-disable */
  // TODO Remove Swiper Library for native solution
  var _default = _exports.default = _component.default.extend({
    adTypes: ["Static Ad with Tagline", "Static Ad with Background Image", "Static Carousel Ad"],
    didInsertElement() {
      this._super(...arguments);
      const swiper = new Swiper(".ad-example-swiper", {
        // Optional parameters
        direction: "vertical",
        // loop: true,
        slidesPerView: 1,
        autoplay: false,
        centeredSlides: true,
        // If we need pagination
        pagination: {
          el: ".swiper-pagination"
        },
        // Navigation arrows
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev"
        }
      });
    }
  });
});