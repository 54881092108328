define("discourse/plugins/stemaway-custom-pages/discourse/components/stem-tab-previews", ["exports", "@ember/component", "discourse-common/utils/decorators", "I18n"], function (_exports, _component, _decorators, _I18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend(dt7948.p({
    tagName: "div",
    classNames: ["stem-tab-previews"],
    tabData(prefix) {
      const data = [];
      let i = 1;
      while (_I18n.default.t(`${prefix}.tab_${i}.title`)[0] !== "[" && _I18n.default.t(`${prefix}.tab_${i}.title`).substr(-1) !== "]") {
        data.push({
          id: `stem-tab-${i}`,
          title: _I18n.default.t(`${prefix}.tab_${i}.title`),
          content: _I18n.default.t(`${prefix}.tab_${i}.content`)
        });
        i++;
      }
      return data;
    }
  }, [["method", "tabData", [(0, _decorators.default)("prefix")]]]));
});