define("discourse/plugins/stemaway-custom-pages/discourse/controllers/apply", ["exports", "@ember/controller", "discourse-common/utils/decorators", "I18n"], function (_exports, _controller, _decorators, _I18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _controller.default.extend(dt7948.p({
    quickLinks() {
      const prefix = "pages.apply.heading.buttons.quick_links";
      const linkSet = [];
      let i = 1;
      while (_I18n.default.t(`${prefix}.link_${i}.label`)[0] !== "[" && _I18n.default.t(`${prefix}.link_${i}.label`).substr(-1) !== "]") {
        linkSet.push({
          link: _I18n.default.t(`${prefix}.link_${i}.link`),
          icon: _I18n.default.t(`${prefix}.link_${i}.icon`),
          label: _I18n.default.t(`${prefix}.link_${i}.label`)
        });
        i++;
      }
      return linkSet;
    }
  }, [["method", "quickLinks", [(0, _decorators.default)()]]]));
});