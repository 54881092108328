define("discourse/plugins/stemaway-custom-pages/discourse/components/stem-features-block", ["exports", "@ember/component", "discourse-common/utils/decorators", "I18n"], function (_exports, _component, _decorators, _I18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend(dt7948.p({
    tagName: "div",
    classNames: ["stem-features-block"],
    featureSet() {
      const prefix = this.get("prefix");
      const featureSet = [];
      let i = 1;
      while (_I18n.default.t(`${prefix}_${i}.title`)[0] !== "[" && _I18n.default.t(`${prefix}_${i}.title`).substr(-1) !== "]") {
        featureSet.push({
          title: _I18n.default.t(`${prefix}_${i}.title`),
          url: _I18n.default.t(`${prefix}_${i}.link`),
          content: _I18n.default.t(`${prefix}_${i}.content`),
          imgPath: _I18n.default.t(`${prefix}_${i}.img_path`),
          altText: _I18n.default.t(`${prefix}_${i}.alt_text`),
          linkText: _I18n.default.t(`${prefix}_${i}.link_text`)
        });
        i++;
      }
      return featureSet;
    }
  }, [["method", "featureSet", [(0, _decorators.default)("prefix")]]]));
});