define("discourse/plugins/stemaway-custom-pages/discourse/templates/components/testimonial-set", ["exports", "ember-this-fallback/deprecations-helper", "@ember/template-factory"], function (_exports, _deprecationsHelper, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#each testimonialSet as |t|}}
    {{testimonial
      imgPath=t.imgPath
      altText=t.altText
      quote=t.quote
      author=t.author
      title=t.title
    }}
  {{/each}}
  */
  {
    "id": "6vAPvJzf",
    "block": "[[[42,[28,[37,1],[[28,[37,1],[[30,0,[\"testimonialSet\"]]],null]],null],null,[[[1,\"  \"],[1,[28,[35,2],null,[[\"imgPath\",\"altText\",\"quote\",\"author\",\"title\"],[[30,1,[\"imgPath\"]],[30,1,[\"altText\"]],[30,1,[\"quote\"]],[30,1,[\"author\"]],[30,1,[\"title\"]]]]]],[1,\"\\n\"]],[1]],null],[1,[28,[32,0],[\"[[\\\"The `testimonialSet` property path was used in the `discourse/plugins/stemaway-custom-pages/discourse/templates/components/testimonial-set.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.testimonialSet}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}]]\"],null]]],[\"t\"],false,[\"each\",\"-track-array\",\"testimonial\"]]",
    "moduleName": "discourse/plugins/stemaway-custom-pages/discourse/templates/components/testimonial-set.hbs",
    "scope": () => [_deprecationsHelper.default],
    "isStrictMode": false
  });
});