define("discourse/plugins/stemaway-custom-pages/discourse/components/stem-pathway-card", ["exports", "@ember/component", "@ember/object", "discourse/lib/url"], function (_exports, _component, _object, _url) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend(dt7948.p({
    tagName: "div",
    classNames: ["stem-pathway-card"],
    classNameBindings: ["url:stem-card-clickable"],
    click() {
      return _url.default.routeTo(`${this.url}#notification-top`);
    }
  }, [["method", "click", [_object.action]]]));
});