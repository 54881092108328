define("discourse/plugins/stemaway-custom-pages/discourse/components/route-control", ["exports", "@ember/component", "@ember/service", "discourse-common/utils/decorators"], function (_exports, _component, _service, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend(dt7948.p({
    tagName: "div",
    router: (0, _service.inject)(),
    canDisplay(currentURL, restrictToRoute) {
      if (currentURL === restrictToRoute) {
        return true;
      } else {
        return false;
      }
    }
  }, [["method", "canDisplay", [(0, _decorators.default)("router.currentURL", "restrictToRoute")]]]));
});