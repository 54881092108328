define("discourse/plugins/stemaway-custom-pages/discourse/components/testimonial-set", ["exports", "@ember/component", "discourse-common/utils/decorators", "I18n"], function (_exports, _component, _decorators, _I18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend(dt7948.p({
    tagName: "div",
    classNames: ["quote-cards"],
    testimonialSet(prefix) {
      const testimonialSet = [];
      let i = 1;
      while (_I18n.default.t(`${prefix}.testimonial_${i}.title`)[0] !== "[" && _I18n.default.t(`${prefix}.testimonial_${i}.title`).substr(-1) !== "]") {
        testimonialSet.push({
          imgPath: `${prefix}.testimonial_${i}.imgPath`,
          quote: `${prefix}.testimonial_${i}.quote`,
          author: `${prefix}.testimonial_${i}.author`,
          title: `${prefix}.testimonial_${i}.title`
        });
        i++;
      }
      return testimonialSet;
    }
  }, [["method", "testimonialSet", [(0, _decorators.default)("prefix")]]]));
});