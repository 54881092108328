define("discourse/plugins/stemaway-custom-pages/discourse/templates/components/guide-footer", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <footer>
    <div class="guide-section-header">
      <h3>
        {{i18n (concat @prefix ".title")}}
      </h3>
      <p>
        {{html-safe (i18n (concat @prefix ".content"))}}
      </p>
      <ul>
        <li>
          <a href={{i18n (concat @prefix ".forum_link")}}>
            {{i18n
              "pages.guide_full_stack_front_end.sections.footer.forum_button"
            }}
          </a>
        </li>
        <li>
          <a href={{i18n (concat @prefix ".guide_link")}}>
            {{i18n (concat @prefix ".guide_button")}}
          </a>
        </li>
      </ul>
    </div>
  </footer>
  */
  {
    "id": "Wm2zHxDt",
    "block": "[[[10,\"footer\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"guide-section-header\"],[12],[1,\"\\n    \"],[10,\"h3\"],[12],[1,\"\\n      \"],[1,[28,[35,0],[[28,[37,1],[[30,1],\".title\"],null]],null]],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,2],[12],[1,\"\\n      \"],[1,[28,[35,2],[[28,[37,0],[[28,[37,1],[[30,1],\".content\"],null]],null]],null]],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,\"ul\"],[12],[1,\"\\n      \"],[10,\"li\"],[12],[1,\"\\n        \"],[10,3],[15,6,[28,[37,0],[[28,[37,1],[[30,1],\".forum_link\"],null]],null]],[12],[1,\"\\n          \"],[1,[28,[35,0],[\"pages.guide_full_stack_front_end.sections.footer.forum_button\"],null]],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n      \"],[10,\"li\"],[12],[1,\"\\n        \"],[10,3],[15,6,[28,[37,0],[[28,[37,1],[[30,1],\".guide_link\"],null]],null]],[12],[1,\"\\n          \"],[1,[28,[35,0],[[28,[37,1],[[30,1],\".guide_button\"],null]],null]],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[\"@prefix\"],false,[\"i18n\",\"concat\",\"html-safe\"]]",
    "moduleName": "discourse/plugins/stemaway-custom-pages/discourse/templates/components/guide-footer.hbs",
    "isStrictMode": false
  });
});