define("discourse/plugins/stemaway-custom-pages/discourse/components/guide-modules", ["exports", "@ember/component", "discourse-common/utils/decorators", "I18n"], function (_exports, _component, _decorators, _I18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend(dt7948.p({
    tagName: "div",
    classNames: ["guide-modules-cards"],
    moduleSet() {
      const prefix = this.get("prefix");
      const moduleSet = [];
      let i = 1;
      while (_I18n.default.t(`${prefix}.module_${i}.title`)[0] !== "[" && _I18n.default.t(`${prefix}.module_${i}.title`).substr(-1) !== "]") {
        moduleSet.push({
          subtitle: _I18n.default.t(`${prefix}.module_${i}.subtitle`),
          title: _I18n.default.t(`${prefix}.module_${i}.title`),
          url: _I18n.default.t(`${prefix}.module_${i}.link`),
          list: `${prefix}.module_${i}.list`
        });
        i++;
      }
      return moduleSet;
    }
  }, [["method", "moduleSet", [(0, _decorators.default)("prefix")]]]));
});