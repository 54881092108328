define("discourse/plugins/stemaway-custom-pages/discourse/templates/components/universities-ticker", ["exports", "ember-this-fallback/deprecations-helper", "@ember/template-factory"], function (_exports, _deprecationsHelper, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <section class="universities-ticker">
    <h2>{{i18n "pages.ads.section.universities.title"}}</h2>
  
    <div class="unis unis--logobar" id="containerElem">
      <ul class="unis-list" id="unis-list">
        {{#each universities as |u|}}
          <li class="unis-list__item" id={{u}}>
            <img
              src="https://raw.githubusercontent.com/stemaway-repo/stemaway-unified/master/assets/uni-logo-{{u}}.svg"
              alt="{{u}} logo"
              loading="lazy">
          </li>
        {{/each}}
      </ul>
    </div>
  </section>
  */
  {
    "id": "6uJkH2ve",
    "block": "[[[10,\"section\"],[14,0,\"universities-ticker\"],[12],[1,\"\\n  \"],[10,\"h2\"],[12],[1,[28,[35,0],[\"pages.ads.section.universities.title\"],null]],[13],[1,\"\\n\\n  \"],[10,0],[14,0,\"unis unis--logobar\"],[14,1,\"containerElem\"],[12],[1,\"\\n    \"],[10,\"ul\"],[14,0,\"unis-list\"],[14,1,\"unis-list\"],[12],[1,\"\\n\"],[42,[28,[37,2],[[28,[37,2],[[30,0,[\"universities\"]]],null]],null],null,[[[1,\"        \"],[10,\"li\"],[14,0,\"unis-list__item\"],[15,1,[30,1]],[12],[1,\"\\n          \"],[10,\"img\"],[15,\"src\",[29,[\"https://raw.githubusercontent.com/stemaway-repo/stemaway-unified/master/assets/uni-logo-\",[30,1],\".svg\"]]],[15,\"alt\",[29,[[30,1],\" logo\"]]],[14,\"loading\",\"lazy\"],[12],[13],[1,\"\\n        \"],[13],[1,\"\\n\"]],[1]],null],[1,\"    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,[28,[32,0],[\"[[\\\"The `universities` property path was used in the `discourse/plugins/stemaway-custom-pages/discourse/templates/components/universities-ticker.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.universities}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}]]\"],null]]],[\"u\"],false,[\"i18n\",\"each\",\"-track-array\"]]",
    "moduleName": "discourse/plugins/stemaway-custom-pages/discourse/templates/components/universities-ticker.hbs",
    "scope": () => [_deprecationsHelper.default],
    "isStrictMode": false
  });
});